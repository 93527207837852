<template>
  <suy-table :reload="loadData" v-model:columns="columns" >
    <template #search>
      <a-form :model="formState">
        <a-row>
          <a-col :span="4">
            <a-form-item label="">
              <a-select v-model:value="formState.type" placeholder="修改类型">
                <a-select-option value="shanghai">Zone one</a-select-option>
                <a-select-option value="beijing">Zone two</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item style="margin:0px 20px" label="">
              <a-select v-model:value="formState.child" placeholder="子分类">
                <a-select-option value="shanghai">Zone one</a-select-option>
                <a-select-option value="beijing">Zone two</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="2">
            <a-form-item>
              <a-button type="primary" @click="seatchBtn">
                搜索
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </template>
    <template #toolbarRight>
      <a-button type="primary" @click="add">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        新增
      </a-button>
    </template>
    <a-table :dataSource="dataSource" :columns="columns" :rowKey="(record,index)=>{return index}" @change="handleTableChange"  :pagination="pagination" :loading="loading">
      <template #status="{ record }">
        <span :style="{color:record.status==='已修改' ? '#339933':'black'}">{{record.status}}</span>
      </template>
    </a-table>
  </suy-table>
  <a-modal v-model:visible="visible" title="新增修改" @ok="handleOk" :maskClosable="false" @cancel="cancel">
    <a-form :model="formState" layout="vertical">
      <a-form-item label="修改类型：" :wrapperCol="{flex:'auto'}">
        <a-select v-model:value="formState.type.value" allowClear placeholder='请选择修改类型'>
          <a-select-option v-for="item in formState.type" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="子分类：" :wrapperCol="{flex:'auto'}">
        <a-select v-model:value="formState.type.value" allowClear placeholder='请选择子分类'>
          <a-select-option v-for="item in formState.child" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="修改内容：" placeholder='请输入修改内容'>
        <a-textarea v-model:value="formState.remark" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import SuyTable from '@/components/SuyTable'
import { reactive, toRefs } from 'vue'
import { PlusCircleOutlined } from '@ant-design/icons-vue'
import { opsPage } from '@/api/trans/ops/edit'
import { onMounted } from 'vue'
export default {
  components: {
    SuyTable,
    PlusCircleOutlined
  },
  setup () {
    const state = reactive({
      formState: {},
      columns: [
        {
          title: '大类型',
          dataIndex: 'type.label',
          key: 'type.label'
        },
        {
          title: '子分类',
          dataIndex: 'subType.label',
          key: 'subType.label'
        },
        {
          title: '状态',
          dataIndex: 'status.label',
          key: 'status.label'
        },
        {
          title: '发起人',
          dataIndex: 'sponsor',
          key: 'sponsor'
        },
        {
          title: '发起时间',
          dataIndex: 'createTime',
          key: 'createTime'
        },
        {
          title: '操作人',
          dataIndex: 'operation',
          key: 'operation'
        },
        {
          title: '修改时间',
          dataIndex: 'operationTime',
          key: 'operationTime'
        },
        {
          title: '处理备注',
          dataIndex: 'remark',
          key: 'remark'
        }
      ],
      dataSource: [],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      loading: false,
      visible: false
    })
    const handleTableChange = page => {
      state.pagination.current = page.current
      state.pagination.pageSize = page.pageSize
      loadData()
    }
    const formState = reactive({
      type: [],
      child: [],
      remark: ''
    })
    const add = () => {
      state.visible = true
    }
    const handleOk = () => {
      console.log('ok')
    }
    const searchBtn = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = () => {
      state.loading = true
      opsPage({
        ...state.formState,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          // console.log(res.data);
          state.dataSource = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const cancel = () => {
      formState.remark = ''
      formState.type = []
      formState.child = []
    }
    onMounted(() => loadData())
    return {
      ...toRefs(state),
      formState,
      searchBtn,
      loadData,
      add,
      handleOk,
      cancel,
      handleTableChange
    }
  }
}
</script>

<style>
</style>
